/* eslint no-console:0 */

import Rails from "rails-ujs";
import $ from "jquery";
import Turbolinks from "turbolinks";
import Foundation from "foundation-sites";
import * as ActiveStorage from "activestorage";

// Javascript
import "owl.carousel";

// Styles
import "owl.carousel/dist/assets/owl.carousel.css";
import "application.scss";

// Images
import "images/logo.svg";
import "images/logo-small.svg";
import "images/tick.svg";
import "images/tick-outline.svg";
import "images/phone.svg";
import "images/email.svg";
import "images/favicon.png";
import "images/facebook-cover.png";
import "images/sample-image.jpg";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
Foundation.addToJquery($);

$(document).on("turbolinks:load", function() {
  $(document).foundation();

  setTimeout(function() {
    $('#COVID19Popup').foundation('open')
  }, 5000)

  $(window).on("scroll", function() {
    if ($(window).scrollTop() > 0) {
      $(".header").addClass("is-sticky");
    } else {
      $(".header").removeClass("is-sticky");
    }
  });

  $("#js-nav").on("click", function() {
    $(this).toggleClass("is-active");
    $(".nav").toggleClass("is-active");
  });

  $("#js-testimonials").owlCarousel({
    center: true,
    items: 1,
    loop: true,
    responsive: {
      1024: {
        stagePadding: 300
      }
    }
  });
});
